<template>
<div id="Home">
    <div id="header">
        <h1>
        <b>Hi, I'm <u>Dmitry Verov</u></b>
        </h1>
    </div>

    <div id="discr">
      <h3>
I'm a Platform Engineer at SOFTSWISS. I live in Tbilisi, Georgia.
I'm interesting in process of automation, network and python programming.
In my free time I take part in CTFs to improve my security skills. If you want to
contact with me please use <a href="https://t.me/verovd"> Telegram </a> or
<a href="mailto:me@dimaverov.ru"> Email </a>
      </h3>
    </div>
  <div id="column">
      <div v-for="column in conlumns"
      :key="column.name">
      <div id="column_name">
          <h3> {{ column.name }} </h3>
      <div id="column_content">
        <div v-for="item in column.items"
        :key="item">
          <div v-if="column.type==='static'">
            {{ item }}
          </div>
            <div v-else-if="column.type === 'links'">
            <a v-bind:href="item.url"> {{ item.name }}</a>
          </div>
            <div id="column_project" v-else-if="column.type === 'dynamic'">
            <a v-bind:href="item.url"> {{ item.name }}</a>
          </div>
      </div>
      </div>
      </div>
      </div>
  </div>
</div>

</template>


<script>
import store from "@/store.js"
export default {
  name: "Home",
  components: {},
  data() {
      return {
        conlumns: store.destinations
      }
  }
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
}
#header {
  text-align: left;
  color: #2c3e50;
}
#discr {
  text-align: left;
}
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
#column_name {
  float: left;
  width: 25%;
}
#column_content {
  position: inherit;
}
a {
  color: black;
  text-decoration: none;
}
</style>