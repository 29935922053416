export default {
  destinations: [
    {
      name: "Contacts",
      slug: "Contacts",
      type: "links",
      id: 1,
      items: [
        { name: "Telegram", url: "https://t.me/verovd" },
        { name: "Instagram", url: "https://www.instagram.com/dmitryverov/" },
        {
          name: "linkedin",
          url: "https://www.linkedin.com/in/verovd/",
        },
      ],
    },
    {
      name: "Skills",
      slug: "Skills",
      id: 2,
      type: "static",
      items: [
        "Linux Administrator",
        "Network Administrator",
        "CI / CD",
        "Python/Bash/Java",
      ],
    },
    {
      name: "Experience",
      slug: "Experience",
      type: "static",
      id: 3,
      items: ["YooMoney", "Yandex", "Microsoft"],
    },
    {
      name: "Projects",
      slug: "Projects",
      type: "dynamic",
      id: 4,
      items: [
        { name: "Soon" }
      ],
    },
  ],
};
